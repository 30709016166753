import type { BaseData, MainSubStructureModel } from 'egenie-utils';
import { request, ExportModal, ExportStore, Programme, ProgrammeComponent } from 'egenie-utils';
import React from 'react';
import { getAllVendor, getOwner } from '../../../utils';
import { filterItems } from './filterItems';
import { mainSubStructureModel } from './mainSubStructureModel';
import { ChooseReturnOrderModal, ChooseReturnOrderModalStore } from './chooseReturnOrderModal';
import type { MainItem } from './types';
import { printHelper } from 'egenie-common';
import { Modal } from 'antd';

export class Store {
  constructor() {
    getOwner()
      .then((ownerIds) => this.programme.filterItems.addDict({ ownerIds }));
    getAllVendor()
      .then((vendorShopIds) => this.programme.filterItems.addDict({ vendorShopIds }));
  }

  public mainSubStructureModel: MainSubStructureModel = mainSubStructureModel(this);

  public chooseReturnOrderModalStore: ChooseReturnOrderModalStore = new ChooseReturnOrderModalStore(this);

  public programme: Programme = new Programme({
    gridModel: this.mainSubStructureModel,
    filterItems: filterItems(this),
    moduleName: 'egenieCloudWmsPurchaseSupplierReturnOrderIndex',
  });

  public exportStore: ExportStore = new ExportStore({ parent: null });

  public handlePrintUnicode = (item: MainItem) => {
    Modal.confirm({
      content: '确定打印吗?',
      onOk: async() => {
        const info = await request<BaseData<{ tempData: any; userDataList: any[]; }>>({
          url: '/api/cloud/pms/return/order/unique/code/print',
          method: 'post',
          data: {
            wmsReturnOrderId: item.wmsReturnOrderId,
            tempType: '27',
            tempId: '0',
          },
        });

        printHelper.toggleToLodop();
        await printHelper.print({
          preview: false,
          templateData: info?.data?.tempData,
          contents: info?.data?.userDataList,
        });
      },
    });
  };
}

const store = new Store();

export default function() {
  return (
    <>
      <ProgrammeComponent store={store.programme}/>
      <ExportModal store={store.exportStore}/>
      <ChooseReturnOrderModal chooseReturnOrderModalStore={store.chooseReturnOrderModalStore}/>
    </>
  );
}
