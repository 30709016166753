import type { PaginationData } from 'egenie-utils';
import { request, ImgFormatter, TimeStampFormatter } from 'egenie-utils';
import React from 'react';
import type { SubItem } from './types';

export function detailGrid() {
  return {
    tab: {
      name: '商品明细',
      value: 'detail',
    },
    grid: {
      columns: [
        {
          width: 60,
          key: 'pic',
          name: '图片',
          formatter: ({ row }) => <ImgFormatter value={row.pic}/>,
        },
        {
          key: 'productNo',
          sortable: true,
          name: '商品编码',
          width: 200,
        },
        {
          sortable: true,
          key: 'skuNo',
          name: 'SKU编码',
          width: 200,
        },
        {
          key: 'barCode',
          name: '条形码',
          width: 200,
        },
        {
          key: 'uniqueCode',
          name: '唯一码',
          width: 200,
        },
        {
          key: 'newUniqueCode',
          name: '新唯一码',
          width: 200,
        },
        {
          key: 'color',
          name: '颜色',
          width: 100,
        },
        {
          key: 'size',
          name: '尺码',
          width: 100,
        },
        {
          key: 'returnCount',
          name: '退回数量',
          width: 100,
        },
        {
          key: 'returnStatusName',
          name: '退回状态',
          sidx: 'returnStatus',
          sortable: true,
          width: 80,
        },
        {
          key: 'tallyTime',
          name: '理货日期',
          sortable: true,
          width: 150,
          formatter: ({ row }) => <TimeStampFormatter value={row.tallyTime}/>,
        },
        {
          key: 'exitTime',
          name: '退档口日期',
          sortable: true,
          width: 150,
          formatter: ({ row }) => <TimeStampFormatter value={row.exitTime}/>,
        },
        {
          key: 'returnTime',
          name: '返仓日期',
          sortable: true,
          width: 150,
          formatter: ({ row }) => <TimeStampFormatter value={row.returnTime}/>,
        },
        {
          key: 'returnReasonName',
          name: '退回原因',
          width: 150,
        },
        {
          key: 'wmsRefundOrderNo',
          name: '退货单编码',
          width: 200,
        },
        {
          key: 'wmsOtherStockOrderNo',
          name: '入库单号',
          width: 200,
        },
      ].map((info) => ({
        resizable: true,
        sortable: false,
        ...info,
      })),
      rows: [],
      primaryKeyField: 'wmsReturnOrderDetailId',
      sortByLocal: false,
      showCheckBox: false,
      setColumnsDisplay: true,
      gridIdForColumnConfig: '/egenie-cloud-wms/purchaseOrder/supplierReturnOrder/index/detail',
    },
    api: {
      onQuery: ({
        data,
        pid,
      }) => {
        const {
          sidx,
          sord,
          page,
          pageSize,
        } = data;
        return request<PaginationData<SubItem[]>>({
          url: '/api/cloud/pms/return/order/detail/query',
          method: 'POST',
          data: {
            wmsReturnOrderId: pid,
            sidx,
            sord,
            page,
            pageSize,
          },
        });
      },
    },
  };
}
