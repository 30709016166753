export const ENUM_RETURN_ORDER_TYPE = {
  empty: {
    value: 1,
    label: '空白单',
  },
  normal: {
    value: 0,
    label: '正常单',
  },
};
