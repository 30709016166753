import { Button, Input, message, Modal } from 'antd';
import type { PaginationData } from 'egenie-utils';
import { MainSubStructureModel, request } from 'egenie-utils';
import { Observer } from 'mobx-react';
import React from 'react';
import { logSubGrid } from '../../../utils';
import { detailGrid } from './detailGrid';
import type { MainItem } from './types';
import type { Store } from './index';
import { ENUM_RETURN_ORDER_TYPE } from './constants';

export function mainSubStructureModel(context: Store) {
  return new MainSubStructureModel({
    grid: {
      columns: [
        {
          key: 'operate',
          name: '操作',
          width: 100,
          frozen: true,
          formatter: ({ row }: { row: MainItem; }) => {
            if (row.codeMiss === ENUM_RETURN_ORDER_TYPE.empty.value) {
              return (
                <Button
                  onClick={() => context.chooseReturnOrderModalStore.handleOpen(row)}
                  size="small"
                  type="link"
                >
                  关联退货单
                </Button>
              );
            } else if (row.codeMiss === ENUM_RETURN_ORDER_TYPE.normal.value) {
              return (
                <Button
                  onClick={() => context.handlePrintUnicode(row)}
                  size="small"
                  type="link"
                >
                  打印唯一码
                </Button>
              );
            } else {
              return null;
            }
          },
        },
        {
          key: 'wmsReturnOrderNo',
          name: '退回单编码',
          width: 200,
        },
        {
          sortable: true,
          key: 'ownerName',
          name: '货主',
          sidx: 'ownerId',
          width: 200,
        },
        {
          sortable: true,
          key: 'vendorNo',
          sidx: 'vendorShopId',
          name: '供应商',
          width: 200,
        },
        {
          key: 'returnCount',
          name: '退回数量',
          width: 80,
          sortable: true,
        },
        {
          key: 'printTime',
          name: '打印次数',
          width: 80,
        },
        {
          key: 'codeMissLabel',
          name: '类型',
          width: 80,
        },
        {
          name: '备注',
          key: 'note',
          width: 200,
          formatter: ({
            rowIdx,
            row,
          }: { rowIdx: number; row: MainItem; }) => {
            return (
              <Observer>
                {
                  () => (
                    <Input
                      onBlur={() => {
                        request({
                          url: '/api/cloud/pms/return/order/edit/note',
                          method: 'post',
                          data: {
                            wmsReturnOrderId: row.wmsReturnOrderId,
                            note: context.mainSubStructureModel.gridModel.rows[rowIdx].note,
                          },
                        })
                          .then(() => {
                            message.success({
                              key: '备注修改成功',
                              content: '备注修改成功',
                            });
                          });
                      }}
                      onChange={(event) => context.mainSubStructureModel.gridModel.rows[rowIdx].note = event.target.value}
                      onClick={(event) => event.stopPropagation()}
                      size="small"
                      value={context.mainSubStructureModel.gridModel.rows[rowIdx].note}
                    />
                  )
                }
              </Observer>
            );
          },
        },
      ].map((info) => ({
        resizable: true,
        sortable: false,
        ...info,
      })),
      rows: [],
      primaryKeyField: 'wmsReturnOrderId',
      sortByLocal: false,
      showCheckBox: true,
      showEmpty: true,
      pageSize: 200,
      setColumnsDisplay: true,
      gridIdForColumnConfig: '/egenie-cloud-wms/purchaseOrder/supplierReturnOrder/index',
    },
    pageId: '60140',
    buttons: [
      {
        permissionId: '6',
        text: '导出',
        icon: 'icon-export',
        handleClick: () => {
          const ids = Array.from(context.programme.gridModel.gridModel.selectedIds).join(',');
          const fileName = '供应商退回单';
          const exportType = 'cloud_wms_return_order';
          const queryParam = context.programme.filterItems.params;
          const queryParamShow = context.programme.filterItems.translateParams.join(' ');

          if (ids) {
            context.exportStore.onShow(fileName, exportType, ids, queryParam, queryParamShow);
          } else {
            Modal.confirm({
              title: '提示',
              content: '未选择数据将导出全部数据?',
              onOk: () => {
                context.exportStore.onShow(fileName, exportType, '', queryParam, queryParamShow);
              },
            });
          }
        },
      },
    ],

    // 主表查询api
    api: {
      onQuery: (params) => {
        const {
          filterParams = {},
          ...rest
        } = params;
        return request<PaginationData<MainItem>>({
          url: '/api/cloud/pms/return/order/page',
          method: 'POST',
          data: {
            ...filterParams,
            ...rest,
          },
        });
      },
    },
    subTables: {
      activeTab: 'detail',
      tabsFlag: {
        inited: { detail: true },
        searched: {},
      },
      list: [
        // @ts-ignore
        detailGrid(),
        logSubGrid(140000),
      ],
    },
  });
}
