import { Button, Card, message, Modal } from 'antd';
import type { PaginationData } from 'egenie-common';
import { request } from 'egenie-common';
import { MainSubStructure, MainSubStructureModel, NormalProgrammeComponent, NormalProgramme } from 'egenie-utils';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import type { Store } from './index';
import type { MainItem, RefundGoodsItem } from './types';

const commonColumns = [
  {
    key: 'wmsRefundOrderNo',
    name: '退货单号',
    width: 200,
  },
  {
    key: 'wmsOtherStockOrderNo',
    name: '出库单编号',
    width: 200,
  },
  {
    key: 'vendorShopName',
    name: '供应商名称',
    width: 200,
  },
  {
    key: 'ownerName',
    name: '货主',
    width: 200,
  },
  {
    key: 'skuNo',
    name: 'SKU编码',
    width: 200,
  },
  {
    key: 'color',
    name: '颜色',
    width: 100,
  },
  {
    key: 'size',
    name: '尺码',
    width: 100,
  },
  {
    key: 'uniqueCode',
    name: '唯一码',
    width: 200,
  },
];

export class ChooseReturnOrderModalStore {
  constructor(private parent: Store) {
    this.mainSubStructureModel.getFilterParams = () => this.normalProgramme.filterItems.params;
  }

  @observable public visible = false;

  private mainRow: MainItem = {};

  @action public handleOpen = (mainRow: MainItem) => {
    this.mainRow = mainRow;

    this.mainSubStructureModel.gridModel.resetAll();
    this.mainSubStructureModel.gridModel.rows = [];
    this.resultMainSubStructureModel.gridModel.resetAll();
    this.resultMainSubStructureModel.gridModel.rows = [];
    this.normalProgramme.reset();

    this.visible = true;
    this.mainSubStructureModel.onQuery();
  };

  @action public handleClose = () => {
    this.mainRow = {};

    this.mainSubStructureModel.gridModel.resetAll();
    this.mainSubStructureModel.gridModel.rows = [];
    this.resultMainSubStructureModel.gridModel.resetAll();
    this.resultMainSubStructureModel.gridModel.rows = [];
    this.normalProgramme.reset();
    this.visible = false;
  };

  @action private handleAddRowsToResult = (rows: RefundGoodsItem[]): void => {
    if (rows.length === 0) {
      const error = '请选择数据';
      message.warning({
        key: error,
        content: error,
      });
      return;
    }

    const needAddRows = rows.filter((item) => this.resultMainSubStructureModel.gridModel.rows.findIndex((val) => val.wmsRefundOrderDetailSnId === item.wmsRefundOrderDetailSnId) === -1);
    needAddRows.forEach((item) => {
      this.resultMainSubStructureModel.gridModel.rows.push(item);
    });
  };

  public mainSubStructureModel: MainSubStructureModel = new MainSubStructureModel({
    grid: {
      columns: [
        {
          key: 'operate',
          name: '操作',
          width: 100,
          frozen: true,
          formatter: ({ row }: { row: RefundGoodsItem; }) => {
            return (
              <Button
                onClick={() => this.handleAddRowsToResult([row])}
                size="small"
                type="link"
              >
                添加
              </Button>
            );
          },
        },
        ...commonColumns,
      ].map((info) => ({
        resizable: true,
        sortable: false,
        ...info,
      })),
      primaryKeyField: 'wmsRefundOrderDetailSnId',
      sortByLocal: false,
      showCheckBox: true,
      showReset: true,
      showEmpty: true,
      pageSize: 200,
    },
    buttons: [
      {
        text: '批量添加',
        handleClick: () => this.handleAddRowsToResult(this.mainSubStructureModel.gridModel.selectRows),
      },
    ],
    api: {
      onQuery: (params) => {
        const {
          filterParams = {},
          ...rest
        } = params;
        this.resultMainSubStructureModel.gridModel.resetAll();
        this.resultMainSubStructureModel.gridModel.rows = [];

        return request<PaginationData<RefundGoodsItem>>({
          url: '/api/cloud/wms/rest/refund/order/detail/unicode/relation',
          method: 'POST',
          data: {
            ...filterParams,
            ...rest,
            wmsReturnOrderId: this.mainRow?.wmsReturnOrderId,
          },
        });
      },
    },
    hiddenSubTable: true,
  });

  @action private handleDeleteResultItem = (index: number): void => {
    this.resultMainSubStructureModel.gridModel.rows.splice(index, 1);
  };

  public resultMainSubStructureModel: MainSubStructureModel = new MainSubStructureModel({
    grid: {
      columns: [
        {
          key: 'operate',
          name: '操作',
          width: 100,
          frozen: true,
          formatter: ({ rowIdx }: { rowIdx: number; }) => {
            return (
              <Button
                onClick={() => this.handleDeleteResultItem(rowIdx)}
                size="small"
                type="link"
              >
                删除
              </Button>
            );
          },
        },
        ...commonColumns,
      ].map((info) => ({
        resizable: true,
        sortable: false,
        ...info,
      })),
      primaryKeyField: 'wmsRefundOrderDetailSnId',
      sortByLocal: false,
      showCheckBox: false,
      showReset: false,
      showEmpty: false,
      showPager: false,
    },
    api: { onQuery: () => Promise.reject() },
    hiddenSubTable: true,
  });

  public normalProgramme: NormalProgramme = new NormalProgramme({
    filterItems: [
      {
        type: 'input',
        field: 'skuNo',
        label: 'SKU编码',
      },
      {
        type: 'input',
        field: 'wmsOtherStockOrderNo',
        label: '出库单编号',
      },
      {
        type: 'input',
        field: 'wmsRefundOrderNo',
        label: '退货单号',
      },
    ],
    count: 4,
    handleSearch: () => this.mainSubStructureModel.onQuery(),
  });

  @observable public isLoading = false;

  @action public handleFinish = () => {
    this.isLoading = true;
    request({
      url: '/api/cloud/pms/return/order/refund/relation',
      method: 'POST',
      data: {
        wmsRefundOrderDetailSnIds: this.resultMainSubStructureModel.gridModel.rows.map((item) => item.wmsRefundOrderDetailSnId),
        wmsReturnOrderId: this.mainRow.wmsReturnOrderId,
      },
    })
      .then(() => {
        message.success('操作成功');
        this.handleClose();
        this.parent.mainSubStructureModel.gridModel.onRefresh();
        if (this.mainSubStructureModel.subTablesModel.activeTab === 'detail') {
          this.mainSubStructureModel.subTablesModel.listModel.find((item) => item.tab.value === 'detail')
            .gridModel
            .onRefresh();
        }
      })
      .finally(() => this.isLoading = false);
  };
}

@observer
export class ChooseReturnOrderModal extends Component<{ chooseReturnOrderModalStore: ChooseReturnOrderModalStore; }> {
  render() {
    const {
      chooseReturnOrderModalStore: {
        handleClose,
        visible,
        normalProgramme,
        mainSubStructureModel,
        resultMainSubStructureModel,
        handleFinish,
        isLoading,
      },
    } = this.props;
    return (
      <Modal
        centered
        destroyOnClose
        maskClosable={false}
        okButtonProps={{
          disabled: resultMainSubStructureModel.gridModel.rows.length <= 0,
          loading: isLoading,
        }}
        onCancel={handleClose}
        onOk={handleFinish}
        title="选择退货单"
        visible={visible}
        width={1100}
      >
        <Card size="small">
          <NormalProgrammeComponent store={normalProgramme}/>
        </Card>
        <div style={{ height: 300 }}>
          <MainSubStructure store={mainSubStructureModel}/>
        </div>
        <div style={{ height: 300 }}>
          <MainSubStructure store={resultMainSubStructureModel}/>
        </div>
      </Modal>
    );
  }
}

