import type { FilterItemOptions } from 'egenie-utils';
import type { Store } from './index';
import { ENUM_RETURN_ORDER_TYPE } from './constants';

export function filterItems(context: Store): FilterItemOptions[] {
  return [
    {
      type: 'date',
      field: 'date',
      label: '日期',
      data: [
        {
          value: 'tallyTime',
          label: '理货日期',
        },
        {
          value: 'exitTime',
          label: '退档口日期',
        },
        {
          value: 'returnTime',
          label: '返仓日期',
        },
      ],
    },
    {
      type: 'select',
      mode: 'multiple',
      field: 'ownerIds',
      label: '货主',
    },
    {
      type: 'select',
      mode: 'multiple',
      field: 'vendorShopIds',
      label: '供应商',
    },
    {
      type: 'input',
      field: 'wmsReturnOrderNo',
      label: '退回单编码',
    },
    {
      type: 'input',
      field: 'wmsRefundOrderNo',
      label: '退货单编码',
    },
    {
      type: 'input',
      field: 'skuNo',
      label: 'SKU编码',
    },
    {
      type: 'input',
      field: 'productNo',
      label: '商品编码',
    },
    {
      type: 'input',
      field: 'uniqueCode',
      label: '唯一码',
    },
    {
      type: 'input',
      field: 'newUniqueCode',
      label: '新唯一码',
    },
    {
      type: 'input',
      field: 'wmsOtherStockOrderNo',
      label: '入库单号',
    },
    {
      type: 'select',
      field: 'codeMiss',
      label: '类型',
      data: Object.values(ENUM_RETURN_ORDER_TYPE).map((item) => ({
        ...item,
        value: `${item.value }`,
      })),
    },
  ];
}
